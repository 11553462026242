import styled from "styled-components";


function CoverContainer({setShowCover}) {

    function handleClick() {
        setShowCover(false);
    }
    return (
        <>
            <CoverContainerStyled onClick = {handleClick}>
                {/* <LogoContainerStyled>
                    <img src = "/leBleu-menu-designs/LB-Logo-Black.png"/>
                </LogoContainerStyled> */}
                <CoverImageContainerStyled>
                    <img src = "/leBleu-menu-designs/le-bleu-west-walk-cover.jpg"/>
                </CoverImageContainerStyled>
                <MenuImageStyled>
                    <img src = "/leBleu-menu-designs/lb-westwalk-menu botton.jpg"/>
                </MenuImageStyled>
            </CoverContainerStyled>
        </>
    )
}

const CoverContainerStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    gap: 60px;
    padding: 30px;
    margin-top: -10px;
 
`

const LogoContainerStyled = styled.div`
    position: relative;
    width: 35%;
    margin-top: 55px;
    

    img {
        width: 100%;
    }
`

const MenuImageStyled = styled.div`
    position: relative;
    width: 50%;
    margin-top: 10px;


    img {
        width: 100%;
    }
`

const CoverImageContainerStyled = styled.div`
    position: relative;
    width: 80%;
    

    img {
        width: 100%;
        height: auto;
    }
`

export default CoverContainer;