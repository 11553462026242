const coffee = [
    {
        name_en: "Flat White",
        description_en: "",
        name_ar: "فلات وايت ",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/01-Coffee-Flat White-72dpi.jpg"
    },

    {
        name_en: "Espresso",
        description_en: "",
        name_ar: "إسبرسو ",
        description_ar: "إسبرسو دبل ",
        price: "14",
        img_path: "/five-am-menu-designs/coffee/02-Coffee-Espresso-72dpi.jpg"
    },

    {
        name_en: "Espresso Double",
        description_en: "",
        name_ar: "إسبرسو دبل ",
        description_ar: "",
        price: "20",
        img_path: "/five-am-menu-designs/coffee/03-Coffee-Espresso Double-72dpi.jpg"
    },

    {
        name_en: "Cappuccino",
        description_en: "",
        name_ar: "كابتشينو ",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/04-Coffee-Cappuccino-72dpi.jpg"
    },

    {
        name_en: "Latte",
        description_en: "",
        name_ar: "لاتية ",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/05-Coffee-Latte-72dpi.jpg"
    },

    {
        name_en: "Spanish Latte",
        description_en: "",
        name_ar: "لاتيه اسباني ",
        description_ar: "",
        price: "24",
        img_path: "/five-am-menu-designs/coffee/06-Coffee-Spanish Latte-72dpi.jpg"
    },

    {
        name_en: "V60",
        description_en: "",
        name_ar: "في ٦٠ ",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/coffee/07-Coffee-V60-72dpi.jpg"
    },

    {
        name_en: "Cortado",
        description_en: "",
        name_ar: "كورتادو ",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/coffee/08-Coffee-Cortado-72dpi.jpg"
    },

    {
        name_en: "Macchiato",
        description_en: "",
        name_ar: "ماكياتو ",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/coffee/09-Coffee-Macchiato-72dpi.jpg"
    },

    {
        name_en: "Mocha",
        description_en: "",
        name_ar: "موكا ",
        description_ar: "",
        price: "27",
        img_path: "/five-am-menu-designs/coffee/10-Coffee-Mocca-72dpi.jpg"
    },

    {
        name_en: "Turkish Coffee",
        description_en: "",
        name_ar: "قهوة تركية ",
        description_ar: "",
        price: "20",
        img_path: "/five-am-menu-designs/coffee/11-Coffee-Turkish-72dp.jpg"
    },

    {
        name_en: "Chocolate",
        description_en: "",
        name_ar: "شوكولاتة ",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/coffee/Chocolate-WEB2.jpg"
    },

    {
        name_en: "Americano",
        description_en: "",
        name_ar: "أمريكانو ",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/coffee/13-Coffee-Americano-72dpi.jpg"
    },

    {
        name_en: "French Press Coffee",
        description_en: "",
        name_ar: "فرينش برس ",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/14-Coffee-French Press-72dpi.jpg"
    },

    {
        name_en: "Matcha",
        description_en: "",
        name_ar: "متشا",
        description_ar: "",
        price: "27",
        img_path: "/five-am-menu-designs/coffee/Matcha-WEB2.jpg"
    },
]



export default coffee;