import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "./CommonStyled";
import {main_course} from "../../../data/lbCategories/a-la-carte/menuItems";


function CarteMainCourseGrid({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Main Course</span>
            </CategoryHeaderStyled> */}
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/main course/le bleu-main course-fillet green  paper.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Fillet with Green Pepper Sauce
                    <PriceContainerStyled>
                        152
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                    Black angus beef fillet, mustard pepper sauce, mashed potato
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
        شرائح لحم بالفلفل   
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ﻓﻴﻠﻴﻪ ﻟﺤﻢ اﻻﻧﺠﻮس الاسود، ﺻﻠﺼﺔ اﻟﻔﻠﻔﻞ الاخضر، اﻟﺒﻄﺎﻃﺲ اﻟﻤﻬﺮوﺳﻪ  
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/main course/le bleu-main course-baby chicken cured lemon.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Baby Chicken With Cured Lemon
                    <PriceContainerStyled>
                        95
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                    roasted baby chicken marinated in lemon,
                    thyme, garlic, lemon confit, de provence herbs and honey served with white rice                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ﻓﺮوج ﺑﻨﻜﻬﺔ اﻟﻠﻴﻤﻮن اﻟﻤﺨﻠﻞ
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        دﺟﺎج ﺻﻐﻴﺮ ﻣﺸﻮي ﻣﺘﺒﻞ ﺑﺎﻟﻠﻴﻤﻮن، زﻋﺘﺮ، ﺛﻮم، ﻛﻮﻧﻔﻴﺖ اﻟﻠﻴﻤﻮن، أﻋﺸﺎب دي ﺑﺮوﻓﺎﻧﺲ و ﻋﺴﻞ ﻳﻘﺪم ﻣﻊ أرز أﺑﻴﺾ
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/main course/le bleu-main course-miso salmon.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Miso Salmon
                    <PriceContainerStyled>
                        96
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                    Norwegian salmon marinated in miso, quinoa and rosemary sauce    
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ﺳﻠﻤﻮن ﻣﺸﻮي ﻣﻴﺰو                        
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ﺳﻠﻤﻮن اﻟﻨﺮوﻳﺠﻲ ﻣﻊ ﺻﻠﺼﺔ اﻟﻤﻴﺰو، اﻟﻜﻴﻨﻮا ﻣﻊ ﺻﻮص اﻛﻠﻴﻞ اﻟﺠﺒﻞ
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-penne pasta white sauce.png" alt = "penne pasta white sauce"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Penne Pasta White Sauce
                    <PriceContainerStyled>
                        53
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Penne pasta in creamy white sauce and oyster mushroom and parmesan cheese</p>
                        <p>Add: Chicken / 18 QR • Shrimps / 20 QR • Veg / 12 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بيني باستا بالصلصه البيضاء
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بينى باستا, بصلصة الكريمة البيضاء، فطر مع جبنة بارميزان
                        <p>اضافة: الدجاج / ١٨ ر.ق • الروبيان / ٢٠ر.ق • خضروات / 12 ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-penne pasta pink sauce.png" alt = "penne pasta pink sauce dish"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Penne Pasta Pink Sauce
                    <PriceContainerStyled>
                        53
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Penne pasta in crispy pink sauce, parmesan cheese and mushroom</p>
                        <p>Add: Chicken / 18 QR • Shrimps / 20 QR • Veg / 12QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بيني باستا بالصلصه الوردية
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بينى باستا بالصلصه الوردية، فطر و بارميزان
                        <p>اضافة: الدجاج / ١٨ ر.ق • الروبيان / ٢٠ر.ق • خضروات / 12 ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-truffle penne.png" alt = "penne pasta truffle penne dish"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Truffle Penne
                    <PriceContainerStyled>
                        95
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Penne pasta with truffle cream sauce, top up with fresh white truffle</p>
                        <p>Add: Chicken / 18 QR • Shrimps / 20 QR • Veg / 12 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بيني الكمأة
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        مكرونة بيني مع صلصة كريمة الكمأة، مضاف إليها الكمأ الابيض الطازج
                        <p>اضافة: الدجاج / ١٨ ر.ق • الروبيان / ٢٠ر.ق • خضروات / 12 ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-duck risotto.png" alt = "penne pasta duck risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Duck Risotto
                    <PriceContainerStyled>
                        155
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Risotto with fresh pan fried duck breast, spicy honey teriyaki sauce, with parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ريزوتو البط 
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ريزوتو مع صدر البط المقلي الطازج، صوص ترياكي بالعسل الحار، مع جبنة البارميزان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-shrimp risotto.png" alt = "penne pasta shrimp risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Shrimp Risotto
                    <PriceContainerStyled>
                        103
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Risotto with sauted shrimp, spicy honey teriyaki sauce with parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ريزوتو الروبيان
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ريزوتو مع روبيان سوتيه، صوص ترياكي بالعسل الحار، مع جبن بارميزان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-chicken risotto.png" alt = "penne pasta chicken risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Chicken Risotto
                    <PriceContainerStyled>
                        96
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Risotto with grilled chicken breast, spicy honey teriyaki sauce, parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ريزوتو الدجاج
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ريزوتو مع صدور الدجاج المشوي، صوص ترياكي بالعسل الحار، مع جبنة البارميزان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/sandwiches/le bleu-sandwiches- club sandwich tuna.png" alt = "penne pasta chicken risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Club Sandwich Chicken
                    <PriceContainerStyled>
                        47
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>chicken, eggs, cheddar cheese, emmental cheese, bacon,turkey, tomato, lettuce & jalapeño mayo</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ﻛﻠﻮب ﺳﻨﺪوﻳﺶ اﻟﺪﺟﺎج
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        دﺟﺎج، ﺑﻴﺾ، ﺟﺒﻨﺔ الإﻣﻨﺘﺎل، ﺟﺒﻨﺔ اﻟﺸﻴﺪر، ﻟﺤﻢ ﻣﻘﺪاد ﺣﺒﺶ، ﻃﻤﺎﻃﻢ، ﺧﺲ وﺻﻠﺼﺔ اﻟﻬﺎﻟﺒﻴﻨﻮ ﻣﺎﻳﻮ
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/sandwiches/le bleu-sandwiches-mac burger.png" alt = "penne pasta chicken risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Mac Burger
                    <PriceContainerStyled>
                        69
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>beef, cheddar cheese, burger bun, sweet relish sauce, white onion</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ﻣﺎك ﺑﺮﻏﺮ
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ﻟﺤﻢ، ﺟﺒﻦ اﻟﺸﻴﺪر، ﺑﺼﻞ اﺑﻴﺾ، ﺻﻮص اﻟﺮﻳﻠﻴﺶ اﻟﺤﻠﻮة
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
            </CategoryContainerStyled>
        </>
    )
}

export default CarteMainCourseGrid;