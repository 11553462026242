import {BreakfastEggs, BreakfastPudding, BreakfastToasts} from "./"
import { SearchBarContainerStyled, AddItemButtonStyled, HideItemButtonStyled, SearchTextFieldStyled, SearchIconStyled, CopyRightStyled, LogoutContainerStyled, GeneralButtonStyled } from "../CommonStyled";
import {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark, faEye, faPlus, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import axios from "axios";
import {Link} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import {ThemeProvider} from "@mui/material/styles"
import {Button} from "@mui/material";



//this component contains all the children components of breakfast
function Breakfast({breakfastEls, navBarTwoRef, auth, handleLogout}) {

    const [breakfastItems, setBreakfastItems] = useState({
        "EGGS": [],
        "TOATS": [],
        "PUDDING & YOGURT": [],
    })
    const [searchQuery, setSearchQuery] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [showHiddenItems, setShowHiddenItems] = useState(false);
    const [loading, setLoading] = useState(true);
    //const url = "https://www.lovelysunnydayapi.com/api/v1/testMenus";

    const url = process.env.NODE_ENV == "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    console.log(url);
    console.log("we are in : ", process.env.REACT_APP_API_URL_PROD)
    let categoriesIndices = ['0', '1', '2'];
    const breakfastSections = useRef([]);

    console.log(process.env.NODE_ENV);

    //tracks the sections id as we scroll
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;
    
        //get id of the current active category as we scroll
        breakfastSections.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if(sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        })

        //pass the current active section's id to the nav bar tracker function
        navBarTracker(sectionId)
    }

    //changes the colour of the active category
    function activeCategoryHandler(sectionId) {
        var index = sectionId - 1;
        breakfastEls.current[index].style.color = "#F5A800"

        categoriesIndices.map((item) => {
            if(item != index) {
                breakfastEls.current[item].style.color = "white"
            }
        })
    }

    async function navBarTracker(sectionId) {
        switch(sectionId) {
            //activates the eggs category
            case '1':
                navBarTwoRef.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the toasts category
            case '2':
                navBarTwoRef.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;

            //activates the pudding & yogurt category
            case '3':
                navBarTwoRef.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;
        }

        return "resolved"
    }

    //triggers the search bar when the user clicks on the search icon
    function handleSearchBar() {
        setShowSearch(true);
    }


    //function that sets the
    function handleSearchQuery(event) {
        setSearchQuery(event.target.value);
    }

    function handleShowHidden() {
        setShowHiddenItems(!showHiddenItems);
    }

    //grab all breakfast items on the render of this
    async function getAllItems() {
     
        console.log(url)
        try {
            const link = `${url}/testMenus?search=${searchQuery}&category=breakfast`
            const {data} = await axios.get(link, {
                withCredentials: true,
            })

            // Reset breakfastItems before adding new items
            setBreakfastItems({
                "EGGS": [],
                "TOASTS": [],
                "PUDDING & YOGURT": [],
            });

            console.log("the data it is", data);

            data.items.map((item) => {
                switch(item.sub_category) {
                    case "EGGS":
                        setBreakfastItems(prevItems => ({
                            ...prevItems,
                            "EGGS": [...prevItems["EGGS"], item]
                        }))
                    break;

                    case "TOASTS":
                        setBreakfastItems(prevItems => ({
                            ...prevItems,
                            "TOASTS": [...prevItems["TOASTS"], item]
                        }))
                    break;

                    case "PUDDING & YOGURT":
                        setBreakfastItems(prevItems => ({
                            ...prevItems,
                            "PUDDING & YOGURT": [...prevItems["PUDDING & YOGURT"], item]
                        }))
                    break;

                }
            })
            setLoading(false);
        } catch (error) {
            console.log("encountered an error");
        }
    }

    //fetches the items from the database on first render and whenever we change the search query
    useEffect(() => {
        // console.log(breakfastEls.current);
        getAllItems();
    }, [searchQuery])

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll)
        }

        var timer = null;

        function scrollHandler() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        }

        window.addEventListener("scroll", scrollHandler)

        //clean event
        return () => {
            window.removeEventListener("scroll", scrollHandler)
        }
    }, [])

    
    if(loading) {
        return (
            <>
                <LoadingContainerStyled>
                    <TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#006580"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                    >
                    </TailSpin>
                </LoadingContainerStyled>
            </>
        )
    }

    if(!loading) {
        return (
            <>
                <Link
                    to = "/restaurants/menus/le-bleu/admin/add-item"
                    target = "_blank"
                >
                    {auth.role === "le-bleu" && <AddItemButtonStyled>
                        <FontAwesomeIcon icon = {faPlus} style = {{fontSize: "20px", padding: "0"}} />
                    </AddItemButtonStyled>}
                </Link>
                {auth.role === "le-bleu" && <HideItemButtonStyled onClick = {handleShowHidden}>
                    <FontAwesomeIcon icon = {!showHiddenItems ? faEyeSlash : faEye} style = {{fontSize: "20px", padding: "0"}}/>
                </HideItemButtonStyled>}
                {auth.role === "le-bleu" && <SearchBarContainerStyled showSearch={showSearch} onChange={handleSearchQuery} value={searchQuery}>
                {!showSearch ? <SearchIconStyled onClick = {handleSearchBar} 
                >
                        <FontAwesomeIcon
                            icon = {faMagnifyingGlass} style = {{fontSize: "20px", padding: "0"}}
                        />
                       
                    </SearchIconStyled> : <SearchIconStyled>
                        <FontAwesomeIcon icon = {faXmark} onClick = {() => setShowSearch(false)} />
                        </SearchIconStyled>}
                    {showSearch && <SearchTextFieldStyled placeholder = "Search here..."/>}
                </SearchBarContainerStyled>}
                { breakfastItems["EGGS"] && breakfastItems["EGGS"].length !=0 && 
                    <section id = "1" ref = {(el) => breakfastSections.current[0] = el}>
                         <BreakfastEggs breakfastEggs = {breakfastItems["EGGS"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                    </section>}
                {breakfastItems["TOASTS"] && breakfastItems["TOASTS"].length !=0 && 
                    <section id = "2" ref = {(el) => breakfastSections.current[1] = el}>
                        <BreakfastToasts breakfastToasts = {breakfastItems["TOASTS"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                    </section>}
                {breakfastItems["PUDDING & YOGURT"] && breakfastItems["PUDDING & YOGURT"].length !=0 && 
                    <section id = "3" ref = {(el) => breakfastSections.current[2] = el}>
                        <BreakfastPudding breakfastPudding = {breakfastItems["PUDDING & YOGURT"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                    </section>}
                <CopyRightStyled style = {{marginTop: "110px"}}>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                        <img src = "/Developed-By-LSD.svg"/>
                    </a>
                    <LogoutContainerStyled>
                        <ThemeProvider theme = {GeneralButtonStyled}>
                            <Button
                                onClick = {handleLogout}
                                variant = "contained"
                            >
                                {auth.role === "le-bleu" ? <span>LOG OUT</span> : <span>ADMIN</span>}
                            </Button>
                        </ThemeProvider>
                    </LogoutContainerStyled>
                </CopyRightStyled>
            </>
        )
    }
}

const LoadingContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    top: 120px;
    background-color: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index:10;
`

export default Breakfast;