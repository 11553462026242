 const beverages = [
    {
        name_en: "Watermelon & Lychee",
        description_en: "",
        name_ar: "ليتشي وبطيخ",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/beverages/01 Watermelon Cocktail_WEB.jpg"
    },

    {
        name_en: "Peach Cocktail",
        description_en: "",
        name_ar: "كوكتيل خوخ ",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/02 Peach Cocktail_WEB.jpg"
    },

    {
        name_en: "Mix Berries Cocktail",
        description_en: "",
        name_ar: "كوكتيل ميكس بيري",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/03 Mix Berries Cocktail_WEB.jpg"
    },

    {
        name_en: "Orange Juice",
        description_en: "",
        name_ar: "عصير برتقال",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/04 Orange Juice_WEB.jpg"
    },

    {
        name_en: "Lemon Mint",
        description_en: "",
        name_ar: "ليمون نعناع",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/05 Lemon Mint_WEB.jpg"
    },

    {
        name_en: "Lemonade Blossom",
        description_en: "",
        name_ar: "ليمون نعناع بماء الزهر",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/06 Lemonade Blossom_WEB.jpg"
    },

    {
        name_en: "Apple, Carrot, Orange",
        description_en: "",
        name_ar: "التفاح، الجزر، البرتقال",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/beverages/07 Apple, Carrot, Orange_WEB.jpg"
    },

    {
        name_en: "Passsion Fruit Iced Tea",
        description_en: "",
        name_ar: "شاي مثلج بالباشن فروت",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/08 Passion Fruit Iced Tea_WEB.jpg"
    },

    {
        name_en: "Peach Iced Tea",
        description_en: "",
        name_ar: "شاي مثلج بالخوخ ",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/09 Peach Ice Tea_WEB.jpg"
    },

    {
        name_en: "Martini Coffee Cream",
        description_en: "",
        name_ar: "مارتيني القهوة بالكريمة ",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/10 Martini Coffee_WEB.jpg"
    },

    {
        name_en: "Virgin Mojito",
        description_en: "",
        name_ar: "موهيتو",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/11 Virgin Mojito_WEB.jpg"
    },

    {
        name_en: "Passion Fruit Mojito",
        description_en: "",
        name_ar: "موهيتو باشن فروت",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/12 Passion Fruit Mojito_WEB.jpg"
    },

    {
        name_en: "Strawberry Mojito",
        description_en: "",
        name_ar: "موهيتو فراولة",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/13 Strawberry Mojito_WEB.jpg"
    },

    {
        name_en: "Berry Mojito",
        description_en: "",
        name_ar: "موهيتو بيري",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/14 Berry Mojito_WEB.jpg"
    },

    {
        name_en: "Soft Drinks",
        description_en: "",
        name_ar: "المشروبات الغازية",
        description_ar: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/15 Soft Drinks_WEB.jpg"
    },

    {
        name_en: "Still Water 0.33 ml",
        description_en: "",
        name_ar: "مياه معدنية ٠٫٣٣ مل ",
        description_ar: "",
        price: "12",
        img_path: "/five-am-menu-designs/beverages/16 Still Water 033ML_WEB.jpg"
    },

    {
        name_en: "Still Water 0.75 ml",
        description_en: "",
        name_ar: "ماء معدنية ٠٫٧٥ مل ",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/beverages/17 Still Water 075ML_WEB.jpg"
    },

    {
        name_en: "Sparkling Water 0.33 ml",
        description_en: "",
        name_ar: "ماء غازية ٠٫٣٣ مل",
        description_ar: "",
        price: "12",
        img_path: "/five-am-menu-designs/beverages/18 Sparkling Water 033ML_WEB.jpg"
    },

    {
        name_en: "Sparkling Water 0.75 ml",
        description_en: "",
        name_ar: "ماء غازية ٠٫٧٥ مل",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/beverages/19 Sparkling Water 075ML_WEB.jpg"
    },

   
]

export default beverages;