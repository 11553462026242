import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "./CommonStyled";


function CarteSaladsGrid({}) {
    return (
        <>
            <CategoryContainerStyled>
                {/* <CategoryHeaderStyled>
                    <span>Salad</span>
                </CategoryHeaderStyled> */}
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-burrata.png" alt = " Burrata Salad"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Burrata Salad
                        <PriceContainerStyled>
                            96
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Fresh burrata cheese, cherry tomatoes, sherry vinaigrette, basil, red currant, extra virgin olive oil</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة البوراتا
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        جبن بوراتا، طماطم كرزية، خل كرز، أوراق ريحان صغيرة، وزيت زيتون
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                {/* <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-argula salad.png" alt = " Argula Salad"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Aragula Salad
                        <PriceContainerStyled>
                            35
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Argaula, confit cherry tomato, shaved parmesan, raspberry and balsamic sauce</p>
                        <p>Add: Chicken / 16 QR • Shrimps / 20 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة الجرجير
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        جرجير طماطم مجففة مع جبنة البرميزان توت وخل البلسمك 
                        <p>اضافة: الدجاج / ١٦ ر.ق • الروبيان / ٢٠ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled> */}
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-caeser salad.png" alt = "caesar salad"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Caesar Salad
                        <PriceContainerStyled>
                            53
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Baby lettuce, caesar dressing, chicken breast, baguette bread, garlic, parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة السيزر
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                            <p>خس، صلصة السيزر، صدر الدجاج، جبن البرميزان، خبز محمص مع الثوم</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                {/* <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-roasted beetroot.png" alt = "roasted beetroot"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Roasted Beetroot Salad
                        <PriceContainerStyled>
                            48
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Roasted beets, goat cheese puree, orange gel, citrus vinaigrette</p>
                        <p>Add: Chicken / 16 QR • Shrimps / 20 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة الشمندر
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        شمندر محمص مع جبنة الماعز المخفوق مع جل البرتقال
                        <p>اضافة: الدجاج / ١٦ ر.ق • الروبيان / ٢٠ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled> */}
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-quinoa kale salad.png" alt = "roasted beetroot"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Quinoa Kale Salad
                        <PriceContainerStyled>
                            43
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Quinoa and kale salad with honey mustard dijon</p>
                        <p>Add: Chicken / 16 QR • Shrimps / 20 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة الكينوا والكيل
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                            بذور الكينوا والكيل مع العسل والخردل
                            <p>اضافة: الدجاج / ١٦ر.ق • الروبيان / ٢٠ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                {/* <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-slow light kale.png" alt = "roasted beetroot"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Slow & Light Kale
                        <PriceContainerStyled>
                            43
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>kale, green apple, roasted squash, feta cheese, dry berry, pumkin seeds</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة الكرنب
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        كرنب، تفاح اخضر، جبنة الفتة، التوت المجفف بذور القرع الاسكواتش المحمص
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled> */}
            </CategoryContainerStyled>
        </>
    )
}


export default CarteSaladsGrid;