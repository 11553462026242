import styled from "styled-components";

function Turquoise({}) {



    return (
        <>
            <PageStyled>
                <iframe
                    src = "https://drive.google.com/file/d/12iTDteEQGPJMBubl2JLiA_CVEuRHYtre/preview"
                    width = "100%"
                    height = "100%"
                    allow = "autoplay"
                />
            </PageStyled>
        </>
    )
}

const PageStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
`

export default Turquoise;