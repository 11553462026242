
import './App.css';
import {BrowserRouter as Router, Routes as Switch, Route, useLocation} from "react-router-dom";
import {Lsd, FoodMenu, ShaqabMenu, LtbMenu, ChariotMenu, AfterNoonTeaMenu, ErgonMenu, LeBleuMenu, LeBleuMenuEv, LsdAbout, LsdContact, LsdIntroPage, LeBleuMenuNew, LunchroomMenu, Turquoise, FiveAmNew} from "./pages";
import {TestAdmin, UpdateItem, EditItem, AdminLogin, AllRestaurantsLogin, RestaurantsDashboard, RestaurantsList } from "./adminPages";
import { ScrollToTop} from './components';
import { AnimatePresence } from 'framer-motion';
import RequireAuth from './components/RequireAuth';
import {AddItemChariot, UpdateItemChariot, EditItemChariot} from "./adminPages/restaurants/chariot"
import {BaronMenuNew, BaronMenuNewAr} from "./pages/BaronMenu/"
import {HabraMenuEn, HabraMenuAr} from "./pages/HabraMenu/";
import { WcsMenuAspireEn, WcsMenuAspireAr } from './pages/wcsAspireShaqabMenu';
import {WcsMenuWestBayEn, WcsMenuWestBayAr} from "./pages/wcsWestbayMenu";
import { FiveAmMenuAr, FiveAmMenuEn } from './pages/fiveAamMenu';
import SekKhalifaMenu from './pages/SekKhalifaMenu';


function App() {

  const location = useLocation();
  return (
    // <div className="App">
      <div className="layout">
          <ScrollToTop/>
          <AnimatePresence>
            <Switch location = {location} key = {location.pathname}>
                <Route exact path = "/" element = {<LsdIntroPage/>}/>
                <Route  path = "/home" element = {<Lsd/>}/>
                <Route path = "/about" element = {<LsdAbout/>}/>
                <Route path = "/contact-us" element = {<LsdContact/>}/>
                <Route path = "/restaurants/menus/shawarma-el-khebbez" element = {<FoodMenu/>}/>
                <Route path = "/restaurants/menus/shawarma-el-khebbez/shaqab" element = {<ShaqabMenu/>}/>
                <Route path = "/restaurants/menus/shawarma-el-khebbez/khalifa" element = {<SekKhalifaMenu/>}/>
                {/* <Route path = "/restaurants/menus/white-coffee-spot" element = {<WcsMenuEn/>}/>
                <Route path = "/restaurants/menus/white-coffee-spot-ar" element = {<WcsMenuAr/>}/> */}
                <Route path = "/restaurants/menus/white-coffee-spot-aspire" element = {<WcsMenuAspireEn/>}/>
                <Route path = "restaurants/menus/white-coffee-spot-aspire-ar" element = {<WcsMenuAspireAr/>}/>
                <Route path = "/restaurants/menus/white-coffee-spot-westbay" element = {<WcsMenuWestBayEn/>}/>
                <Route path = "restaurants/menus/white-coffee-spot-westbay-ar" element = {<WcsMenuWestBayAr/>}/>
                <Route path = "/restaurants/menus/le-train-bleu" element = {<LtbMenu/>}/>
                <Route path = "/restaurants/menus/chariot" element = {<ChariotMenu/>}/>
                <Route element = {<RequireAuth requiredRole= "chariot" />}>
                  <Route path = "/restaurants/menus/chariot/admin/update-item" element  = {<UpdateItemChariot/>}/>
                  <Route path = "/restaurants/menus/chariot/admin/add-item" element  = {<AddItemChariot/>}/>
                  <Route path = "/restaurants/menus/chariot/admin/edit-item/:id" element  = {<EditItemChariot/>}/>
                </Route>
                <Route path = "/restaurants/menus/ergon" element = {<ErgonMenu/>}/>
                {/* <Route path = "/restaurants/menus/le-train-bleu/Afternoon-tea" element = {<AfterNoonTeaMenu/>}/> */}
                <Route path = "/restaurants/menus/le-bleu-westwalk" element = {<LeBleuMenu/>}/>
                <Route path = "restaurants/menus/le-bleu" element = {<LeBleuMenuNew />}/>
                <Route path = "/restaurants/menus/le-bleu/admin/login" element = {<AdminLogin/>}/>
                {/* <Route path = "restaurants/menus/admin/restaurants-list" element = {<RestaurantsList/>}/> */}
                <Route element = {<RequireAuth requiredRole = "le-bleu"/>}>
                  <Route path = "/restaurants/menus/le-bleu/admin/update-item" element  = {<UpdateItem/>}/>
                  <Route path = "/restaurants/menus/le-bleu/admin/add-item" element = {<TestAdmin/>}/>
                  <Route path = "/restaurants/menus/le-bleu/admin/edit-item/:id" element = {<EditItem/>}/>
                </Route>
                {/* <Route path = "restaurants/menus/baron" element =  {<BaronMenuNew/>} /> */}
                <Route path = "restaurants/menus/baron" element = {<BaronMenuNew/>}/>
                <Route path = "restaurants/menus/baron-ar" element = {<BaronMenuNewAr/>}/>
                <Route path = "restaurants/menus/habra" element = {<HabraMenuEn/>}/>
                <Route path = "restaurants/menus/habra-ar" element = {<HabraMenuAr/>}/>
                <Route path = "restaurants/menus/turquoise" element = {<Turquoise/>}/>
                <Route path = "restaurants/menus/five-am" element = {<FiveAmMenuEn/>}/>
                <Route path = "restaurants/menus/five-am-ar" element = {<FiveAmMenuAr/>}/>
                {/* <Route path = "restaurants/menus/five-am-ar" element = {<FiveAmMenuAr/>}/> */}
                <Route path = "restaurants/menus/lunchroom" element = {<LunchroomMenu/>}/>
                <Route path = "/restaurants/menus/le-bleu-event" element = {<LeBleuMenuEv/>}/>
                <Route path = "/restaurants/menus/login" element = {<AdminLogin/>}/>
                {/* make this route private to only admin */}
                <Route path = "/restaurants/menus/admin/login" element = {<AllRestaurantsLogin/>}/>
                <Route path = "/restaurants/menus/admin/restaurants" element = {<RestaurantsList/>}/>
                <Route path = "restaurants/menus/admin/restaurants/:name" element = {<RestaurantsDashboard/>}/>
            </Switch>
          </AnimatePresence>
      </div>
    // </div>
  );
}

export default App;

